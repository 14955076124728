import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'

Vue.config.productionTip = false

// Custom App Includes Includes
import appFn from './plugins/functions';
Vue.prototype.$appFn = appFn;

// Global event bus
Vue.prototype.$eventHub = new Vue();

// 3rd-party
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);
import * as moment from 'moment';
Vue.prototype.$moment = moment;
require('moment-timezone');
import VueMeta from 'vue-meta';
Vue.use(VueMeta);
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'fa'
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
