<template>
    <div class="no-print" id="footer">
        <div class="sub-cont mt-0 px-5">
            <v-row class="align-center" no-gutters>
                <v-col cols="12" md="4" lg="4" offset-lg="0">
                    <p class="body-2 text-center mb-0">
                        <img src="/logo.svg" alt="I'm Okay Logo" width="250" class="top-logo" />
                        <br>
                        &copy;{{ $moment().format('YYYY')}} <span class="hidden-sm-and-down">Imokay.me</span>
                        <!-- <br>
                        <router-link to="/terms" class="m-l-5 m-r-5">Terms of Use</router-link> &bull;
                        <router-link to="/privacy" class="m-l-5 m-r-5">Privacy</router-link> -->
                    </p>
                    <v-divider class="hidden-md-and-up mt-3 mb-3" dark />
                </v-col>
                <v-col cols="12" md="2" v-if="user && user.uuid" class="align-self-start">
                    <h3 class="subtitle-1 text-left text-md-left font-weight-medium pl-5">Your Account</h3>
                    <v-list dark dense class="icons-constrained transparent pt-0 no-divs">
                        <v-list-item link to="/app">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 7px;">fa fa-tachometer-alt</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ (user.onboarded) ? 'Dashboard' : 'Onboarding' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>	
                        <v-list-item link exact to="/app/account" v-if="user.onboarded">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 6px;">fa fa-user-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Account Settings</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link to="/app/subscribe" v-if="!user.stripe_id && user.onboarded">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 7px;">fa fa-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Subscribe</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link to="/app/subscribe" v-else v-show="user.onboarded">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 9px;">fa fa-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Subscription</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="2" offset-md="1" class="align-self-start" v-if="!user.uuid">
                    <h3 class="subtitle-1 text-left text-md-left font-weight-medium pl-5">Your Account</h3>
                    <v-list dark dense class="icons-constrained transparent pt-0 no-divs">
                        <v-list-item link to="/signup">
                            <v-list-item-icon>
                                <v-icon color="primary" class="pl-2">fa fa-check-double</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Sign-Up</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>	
                        <v-list-item link to="/login">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 7px;">fa fa-sign-out</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Login</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="2" class="align-self-start">
                    <h3 class="subtitle-1 text-left text-md-left font-weight-medium pl-5">Support</h3>
                    <v-list dark dense class="icons-constrained transparent pt-0 no-divs">
                        <v-list-item link :to="(user && user.onboarded) ? '/app/support' : '/support'" :target="(!user || user.onboarded) ? '_self' : '_blank'">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 7px;">fa fa-question-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>FAQs &amp; Support</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>	
                        <v-list-item link :to="(user && user.onboarded) ? '/app/support' : '/support'" :target="(!user || user.onboarded) ? '_self' : '_blank'">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 7px;">fa fa-envelope</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Contact Us</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="2" class="align-self-start">
                    <h3 class="subtitle-1 text-left text-md-left font-weight-medium pl-5">I&rsquo;mOkay.me</h3>
                    <v-list dark dense class="icons-constrained transparent pt-0 no-divs">
                        <v-list-item link to="/media" :target="(!user.uuid) ? '_self' : '_blank'">
                            <v-list-item-icon>
                                <v-icon color="primary" class="pl-1">fa fa-folder</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Media &amp; Press</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>	
                        <v-list-item link to="/privacy" :target="(!user.uuid) ? '_self' : '_blank'">
                            <v-list-item-icon>
                                <v-icon color="primary" class="pl-1">fa fa-eye-slash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Privacy Policy</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>	
                        <v-list-item link to="/terms" :target="(!user.uuid) ? '_self' : '_blank'">
                            <v-list-item-icon>
                                <v-icon color="primary" style="padding-left: 10px;">fa fa-asterisk</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Terms of Use</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
// Utilities
import { mapState} from 'vuex'

export default {
	data: () => ({
    }),
    props: {
    },
	components: {

	},
	computed: {
		...mapState(['user'])
	},
	methods: {
        
	},
	mounted(){
	},
	watch: {
	}
}
</script>