<template>
    
    <v-navigation-drawer
		color="white"
		v-if="ready"
		:right="$vuetify.breakpoint.smAndDown"
		fill-height
		:class="($vuetify.breakpoint.smAndDown) ? 'left-nav' : 'left-nav rounded elevation-2'"
		:permanent="!$vuetify.breakpoint.smAndDown"
		:fixed="$vuetify.breakpoint.smAndDown"
		:clipped="$vuetify.breakpoint.smAndDown"
		v-model="showMenu"
		:app="$vuetify.breakpoint.smAndDown"
		width="70vw"
		
	>

		<template v-if="$route.path.indexOf('/app') == -1 || !user || !user.uuid">
			<v-list dense class="icons-constrained pt-10 no-divs">
				<v-list-item exact active-class="left-nav-active" link to="/" @click.native="($route.name=='home')?handleScrollView('#top'):$router.push('/#how')">
					<v-list-item-icon>
						<v-icon color="primary" class="pl-1">fa fa-house</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item exact active-class="left-nav-active" link @click.native="($route.name=='home')?handleScrollView('#how'):$router.push('/#how')">
					<v-list-item-icon>
						<v-icon color="primary" class="pl-2">fa fa-check-double</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>How it Works</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item exact active-class="left-nav-active" link @click="($route.name=='home')?handleScrollView('#features'):$router.push('/#how')">
					<v-list-item-icon>
						<v-icon color="primary" class="pl-1">fa fa-cogs</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Features</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item exact active-class="left-nav-active" link to="/support">
					<v-list-item-icon>
						<v-icon color="primary" class="pl-1">fa fa-question-circle</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Support</v-list-item-title>
					</v-list-item-content>
				</v-list-item>				
				<v-list-item exact active-class="left-nav-active" link to="/signup">
					<v-list-item-icon>
						<v-icon color="primary" class="pl-1">fa fa-check-circle</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Sign-Up</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="mt-3 mb-2" />
				<v-list-item exact active-class="left-nav-active" link to="/login">
					<v-list-item-icon>
						<v-icon color="primary" class="pl-1">fa fa-sign-in</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Log-in</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>

		<template v-else>

			<template v-if="user.onboarded">

				<v-list-item class="no-link-decor secondary mb-0" dark link @click="($vuetify.breakpoint.smAndDown) ? handleMenuDisplay() : $router.push('/app/account'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Left Nav: Top Profile Clicked' }});">
					<v-list-item-avatar size="40" class="elevation-3">
						<v-img v-if="user.image" class="img-border" :src="user.image" cover />
						<v-icon v-else class="img-borderd" color="primary">fa fa-user</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="title mb-2">{{ user.name }}</v-list-item-title>
						<v-list-item-subtitle v-if="user.email" class="caption">{{ user.phone || user.email }}</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action v-if="$vuetify.breakpoint.smAndDown">
						<v-icon>fa fa-times-circle</v-icon>
					</v-list-item-action>
					<v-list-item-action v-else>
						<v-icon small>fa fa-cog</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-divider class="mb-2" />

				<v-list dense class="icons-constrained pt-0 no-divs" style="padding-left: 2px;">
					<v-list-item exact active-class="left-nav-active" link to="/app">
						<v-list-item-icon>
							<v-icon color="primary" class="pl-2">fa fa-tachometer-alt</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Dashboard</v-list-item-title>
						</v-list-item-content>
					</v-list-item>	
					<v-list-item link to="/app/contacts">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 7px;">fa fa-address-book</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Emergency Contacts</v-list-item-title>
							<!-- <v-list-item-subtitle>0 saved</v-list-item-subtitle> -->
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/app/notes">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 8px;">fa fa-notes-medical</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Emergency Notes</v-list-item-title>
							<!-- <v-list-item-subtitle>0 saved</v-list-item-subtitle> -->
						</v-list-item-content>
					</v-list-item>
					<!-- <v-list-item link to="/app/addresses">
						<v-list-item-icon>
							<v-icon color="secondary" style="padding-left: 8px;">fa fa-map-marker-alt</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Your Addresses</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
					<v-list-item link to="/app/activity">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 6px;">fa fa-history</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Activity &amp; Alerts</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/app/subscription" v-if="user.stripe_id">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 7px;">fa fa-box</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Manage Subscription</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/app/subscribe" v-if="!user.stripe_id">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 7px;">fa fa-box</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Subscribe</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link to="/app/account">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 6px;">fa fa-user-cog</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Account Settings</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider class="my-2" />
					<v-list-item link exact to="/app/support">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 7px;">fa fa-question-circle</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Support</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider class="my-2" />
					<v-list-item link exact to="/auth/logout">
						<v-list-item-icon>
							<v-icon color="primary" style="padding-left: 7px;">fa fa-sign-out</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Log Out</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</template>
			<template v-else>
				<v-list dense class="icons-constrained pt-5 no-divs">
					<v-list-item exact active-class="left-nav-active" link @click="$eventHub.$emit('cancelreg')">
						<v-list-item-icon>
							<v-icon color="primary" class="pl-1">fa fa-sign-out</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Cancel Registration</v-list-item-title>
						</v-list-item-content>
					</v-list-item>	
				</v-list>
			</template>

		</template>

		<div class="bottom hidden-md-and-up">
			<v-divider class="mb-3" />
			<p class="caption white--text text-center mb-1">
				&copy;{{ $moment().format('YYYY')}} ImOkay.me
				<br>
				<!-- <router-link to="/about" class="white--text m-l-5 m-r-5">About</router-link> &bull; -->
				<router-link to="/terms" class="white--text m-l-5 m-r-5">Terms of Use</router-link> &bull;
                <router-link to="/privacy" class="white--text m-l-5 m-r-5">Privacy</router-link>
			</p>
		</div>

    </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState} from 'vuex'

export default {
	data: () => ({
		goingToPortal: false,
		ready: false,
		showMenu: false,
    }),
    props: {
    },
	components: {

	},
	computed: {
		...mapState(['user'])
	},
	methods: {
		initData(){
			this.ready = true;
		},
		handleMenuDisplay(){
			this.showMenu = !this.showMenu;
		},
		handleScrollView(view){
			this.$vuetify.goTo(view,{ container: 'body' });
			this.showMenu = false;
		},
		goToPortal(){
			if(!this.user.stripe_id){
				this.$router.push('/app/subscribe');
				return;
			}
			this.$router.push('/app/account');
		}
	},
	mounted(){
		this.initData();
		this.$eventHub.$on('showMenu', () => this.handleMenuDisplay() );
	},
	destroyed(){
		this.$eventHub.$off('showMenu', () => this.handleMenuDisplay() );
	},
	watch: {
		user(){
			if(!this.ready && this.user && this.user.uuid){
				this.ready = true;
			}
		}
	}
}
</script>