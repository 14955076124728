import Vue from 'vue'
import VueRouter from 'vue-router'

// Main
const Home = () => import('@/views/Home.vue');
const Support = () => import('@/views/Support.vue');
const Alert = () => import('@/views/Alert.vue');
const AlertDemo = () => import('@/views/AlertDemo.vue');
const Share = () => import('@/views/Share.vue');
const Privacy = () => import('@/views/Privacy.vue');
const Terms = () => import('@/views/Terms.vue');
const Error404 = () => import('@/views/404.vue');
const About = () => import('@/views/About.vue');
const Media = () => import('@/views/Media.vue');

// User
const Subscription = () => import('@/views/settings/Subscription.vue');
const SupportApp = () => import('@/views/SupportApp.vue');
const Contacts = () => import('@/views/settings/Contacts.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const Account = () => import('@/views/settings/Account.vue');
const Activity = () => import('@/views/settings/Activity.vue');
const Notes = () => import('@/views/settings/Notes.vue');
const Subscribe = () => import('@/views/settings/Subscribe.vue');

// Auth
const Login = () => import('@/views/auth/Login.vue');
const Logout = () => import('@/views/auth/Logout.vue');
const Signup = () => import('@/views/auth/Signup.vue');
const EmailVerify = () => import('@/views/auth/EmailVerify.vue');
const Forgot = () => import('@/views/auth/Forgot.vue');
const Recover = () => import('@/views/auth/Recover.vue');


Vue.use(VueRouter)

const routes = [
  // home
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
      ]
    }
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    meta: {
      title: 'Support - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'Our Story - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
      ]
    }
  },
  {
    path: '/media',
    name: 'media',
    component: Media,
    meta: {
      title: 'Media Assets - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'Media and Press assets for ImOkay.me.'
        },
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: {
      title: 'Privacy Policy - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: {
      title: 'Terms of Use - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
        { robots: 'noindex' }
      ]
    }
  },
  // alert
  {
    path: '/alert/:id?',
    name: 'alert',
    component: Alert,
    meta: {
      title: 'Active Alert - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/alertdemo',
    name: 'alertDemo',
    component: AlertDemo,
    meta: {
      title: 'Alert Demo - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
      ]
    }
  },
  // notes share
  {
    path: '/share/:id?',
    name: 'share',
    component: Share,
    meta: {
      title: 'Shared Notes - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {
          name: 'description',
          content: 'ImOkay.me sends check-in texts to your phone and alerts your contacts if you do not reply.'
        },
        { robots: 'noindex' }
      ]
    }
  },
  // user
  {
    path: '/app',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {robots: 'noindex'}
      ]
    }
  },
  {
    path: '/app/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'Account Settings - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/app/activity',
    name: 'activity',
    props: {
      default: true
    },
    component: Activity,
    meta: {
      title: 'Activity and Stats - I\'m Okay',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/app/support',
    name: 'appsupport',
    component: SupportApp,
    meta: {
      title: 'FAQs and Contact - I\'m Okay',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  // {
  //   path: '/app/addresses/:id?',
  //   name: 'addresses',
  //   component: Addresses,
  //   meta: {
  //     title: 'Your Addresses - I\'m Okay - A Personal Safety App.',
  //   }
  // },
  {
    path: '/app/contacts/:id?',
    name: 'contacts',
    component: Contacts,
    meta: {
      title: 'Emergency Contacts - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/app/notes/:id?',
    name: 'notes',
    component: Notes,
    meta: {
      title: 'Emergency Notes - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/app/subscribe',
    name: 'subscribe',
    component: Subscribe,
    meta: {
      title: 'Subscribe - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/app/subscription',
    name: 'subscription',
    component: Subscription,
    meta: {
      title: 'Your Subscription - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  // auth
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      title: 'Sign Up - I\'m Okay - A Personal Safety App.',
      metaTags: [
        {robots: 'noindex'}
      ]
    }
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: 'Logout - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/verifyemail',
    name: 'emailverify',
    component: EmailVerify,
    meta: {
      title: 'Email Verification - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/auth/forgot',
    name: 'forgot',
    component: Forgot,
    meta: {
      title: 'Forgot Password - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '/auth/reset/:token?',
    name: 'reset',
    component: Recover,
    meta: {
      title: 'Reset Password - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
  {
    path: '*',
    name: '404',
    component: Error404,
    meta: {
      title: 'Not Found - I\'m Okay - A Personal Safety App.',
      metaTags: [
        { robots: 'noindex' }
      ]
    }
  },
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  let title = 'I\'m Okay';
  if (nearestWithTitle) {
    title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    title = previousNearestWithMeta.meta.title;
  }
  document.title = title;

  // Send Analytics
  let isDebug = location.href.indexOf('localhost') !== -1;
  window.gtag('event', 'page_view', {
    page_title: title,
    page_location: location.pathname,
    debug_mode: isDebug
  })

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
