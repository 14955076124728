import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'fa',
    theme: {
        themes: {
            light: {
                // primary: '#01497C',
                primary: '#0A81D1',
                secondary: '#0B032D',  //
                accent: '#BD93D8',
                error: '#da3b4b',
                success: '#20BF55',
                warning: '#FB8B24',
                info: '#0A81D1'
            }
        }
    }
});