<template>
	<v-slide-y-reverse-transition>
        <v-app-bar max-width="100vw" id="top-nav" flat fixed app clipped-left height="54" color="secondary" :class="classNames">
			
			<v-toolbar-title class="white--text ml-0 mr-5 hidden-sm-and-down">
				<router-link :to="(user && user.uuid) ? '/app' : ($route.name=='home') ? '#' : '/'" class="white--text" @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: Logo Clicked' }});">
					<div class="top-text">
						<img src="/logo.svg" alt="I'm Okay Logo" width="150" class="top-logo" />
						<span class="d-none title pl-2">I'm Okay</span>
					</div>
				</router-link>
			</v-toolbar-title>

			<p class="text-center d-block white--text d-md-none ma-0 mr-5 pa-0">
				<router-link :to="(user && user.uuid) ? '/app' : '/'" class="white--text" @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: Logo Clicked' }});">
					<div class="top-text">
						<img src="/logo.svg" alt="I'm Okay Logo" width="150" class="top-logo" />
					</div>
				</router-link>
			</p>
		
			<template>

				<v-spacer />

				<v-spacer v-if="!$vuetify.breakpoint.smAndDown" />

				<template v-if="$route.path.indexOf('/app') == -1 || !user || !user.uuid">
					<template v-if="!$vuetify.breakpoint.smAndDown">
						<v-btn rounded color="white" text dark @click.native="($route.name=='home')?$vuetify.goTo('#how',{ container: 'body' }):$router.push('/#how'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: How it Works' }});" class="mr-3">How it Works</v-btn>
						<v-btn rounded color="white" text dark @click.native="($route.name=='home')?$vuetify.goTo('#features',{ container: 'body', offset: 80 }):$router.push('/#features'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: Features' }});" class="mr-3">Features</v-btn>
						<v-btn rounded color="white" text dark to="/support" @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: Support' }});">Support</v-btn>
						<v-btn v-if="!user || !user.uuid" rounded outlined color="white" text dark to="/signup" @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: Sign Up' }});" class="ml-3">Sign Up</v-btn>
						<v-btn v-if="!user || !user.uuid" rounded outlined color="white" text dark to="/login" @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: Login' }});" class="ml-3">Login</v-btn>
					</template>
					<v-btn @click.native="$eventHub.$emit('showMenu'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Top Nav: Show Mobile Nav - Hamburger Btn' }});" icon rounded text dark color="white" class="ma-0 ml-3 hidden-md-and-up">
						<v-icon>fa fa-bars</v-icon>
					</v-btn>
				</template>

				<template v-if="user && user.uuid">

					<template v-if="user.onboarded && $route.path.indexOf('/app') !== -1">
						<template v-if="user.settings.active && hasActiveContacts">
							<v-btn class="hidden-sm-and-down" v-if="!user.on_alert" small text outlined dark @click.native="$router.push('/app')" rounded color="success">
								<v-icon x-small color="success" class="mr-1">fa fa-dot-circle</v-icon> 
								Check-Ins Active
							</v-btn>
							<v-btn class="hidden-sm-and-down" v-else small text outlined dark @click.native="$router.push('/app')" rounded color="error">
								<v-icon small class="mr-1" color="error">fa fa-dot-circle</v-icon> 
								Alert Issued!
							</v-btn>
						</template>
						<template v-else>
							<v-btn class="hidden-sm-and-down" small text outlined dark @click.native="$router.push('/app')" rounded color="error">
								<v-icon small class="mr-1" color="error">fa fa-dot-circle</v-icon> 
								Check-Ins Disabled
							</v-btn>
						</template>
						<v-btn small text class="ml-3 hidden" outlined dark @click.native="$router.push('/app/account')" rounded color="primary">
							<v-icon x-small class="mr-1" color="primary">fa fa-phone</v-icon> 
							{{ user.phone }}
						</v-btn>
					</template>
					<v-menu open-on-hover offset-y max-height="300" min-width="150">
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" :small="$route.path.indexOf('/app') !== -1" @click.native="$router.push('/app')" rounded text dark color="primary" outlined class="ma-0 ml-3 hidden-sm-and-down">
								<v-icon small class="mr-2">fa fa-user</v-icon> <span class="white--text">{{ user.name }}</span>
							</v-btn>
						</template>
						<v-list dense class="fwn icons-constrained" v-if="user.onboarded">
							<v-list-item link @click="$router.push('/app')">
								<v-list-item-icon>
									<v-icon color="primary">fa fa-tachometer-alt</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title class="secondary--text subtitle-1 font-weight-medium">Dashboard</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item link @click="$router.push('/app/account')">
								<v-list-item-icon>
									<v-icon color="primary">fa fa-user-cog</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title class="secondary--text subtitle-1 font-weight-medium">Manage Account</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item link @click="$router.push('/auth/logout')">
								<v-list-item-icon>
									<v-icon color="error" class="pl-1">fa fa-sign-out</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title class="error--text subtitle-1 font-weight-medium">Log Out</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-btn v-if="$route.path.indexOf('/app') !== -1" @click.native="$eventHub.$emit('showMenu')" icon rounded text dark color="white" class="ma-0 ml-3 hidden-md-and-up">
						<v-icon>fa fa-bars</v-icon>
					</v-btn>
				</template>
			</template>

		</v-app-bar>
	</v-slide-y-reverse-transition>
</template>

<script>
// Utilities

import { mapState } from 'vuex'

export default {
	props: {
	},
	data: () => ({
		ready: false,
	}),
	components: {
	},
	computed: {
		...mapState(['user']),
		classNames(){
			if(location.pathname == '/dd1'){
				return 'top-nav no-print align-center landing-top';
			}
			return 'top-nav no-print align-center';
		},
		displayName(){
			return (this.user.name) ? this.user.name.split(' ')[0] : this.user.username;
		},
		hasActiveContacts(){
            let active = 0;
            for(let i in this.user.contacts){
                if(this.user.contacts[i].active){
                    ++active;
                }
            }
            return active > 0;
        }
	},
	methods: {
		
	},
	mounted(){
		this.ready = true;
	},
	created(){
		
	},
	watch: {
		
	}
}
</script>