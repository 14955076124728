export default {

    moneyFromCents(cents) {

        if (!cents) cents = 0;

        return (cents / 100).toFixed(2);

    },

    isEquivalent(a, b) {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    },

    snakeCase(text) {
        return text.replace(/\s+/g, '-').toLowerCase();
    },

    // formats error responses from server
    getErrors(res, getAsArray) {

        if(!res.data){
            return 'Unidentifed error. Please try again.';
        }

        if(typeof res.data.errors !== 'undefined'){

            if (getAsArray){
                let errors = [];
                for (let i in res.data.errors) {
                    errors.push(res.data.errors[i][0]);
                }
                return errors;
            }

            for (let i in res.data.errors) {
                return res.data.errors[i][0];
            }

        }

        return res.data;

    },

    updateQueryString: function (key, value, url) {
        if (!url) url = '';
        var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
            hash;

        if (re.test(url)) {
            if (typeof value !== 'undefined' && value !== null) {
                return url.replace(re, '$1' + key + "=" + value + '$2$3');
            }
            else {
                hash = url.split('#');
                url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                    url += '#' + hash[1];
                }
                return url;
            }
        }
        else {
            if (typeof value !== 'undefined' && value !== null) {
                var separator = url.indexOf('?') !== -1 ? '&' : '?';
                hash = url.split('#');
                url = hash[0] + separator + key + '=' + value;
                if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                    url += '#' + hash[1];
                }
                return url;
            }
            else {
                return url;
            }
        }
    },

    moneyFormat: function (val, decimals, decimal_sep, thousands_sep) {
        let n = val,
            c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
            d = decimal_sep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

            /*
            according to [https://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
            the fastest way to check for not defined parameter is to use typeof value === 'undefined' 
            rather than doing value === undefined.
            */
            t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep, //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

            sign = (n < 0) ? '-' : '',

            //extracting the absolute value of the integer part of the number and converting to string
            i = parseInt(n = Math.abs(n).toFixed(c)) + '',

            j = ((j = i.length) > 3) ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
    },

    slugify: function (string) {
        const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
        const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    },

    setTitle(title, noAppend){
        document.title = (noAppend) ? title : title + ' - Treet';
    },

    // Helper for creating the AXIOS promise object 
    callApi: function (request) {

        let headers = {};
        if (request.header) {
            headers = request.header;
        }

        // default headers
        //headers['X-FAVORED-APP'] = 'FAV108';

        let testUrl = (window.location.search.indexOf('?test') !== -1) ? 'https://imokay.me/api' : 'https://f944-99-87-236-60.ngrok.io/api';
        testUrl = (window.location.href.indexOf('imokay.me') !== -1) ? 'https://imokay.me/api' : testUrl;
        if (request.prodOnly){
            testUrl = 'https://imokay.me/api';
        }
        // TEMP
        testUrl = 'https://imokay.me/api';
        
        headers['Authorization'] = 'Bearer ' + request.apiKey || null;
        return window.axios({
            method: request.method || 'get',
            data: request.data,
            url: testUrl + request.endpoint,
            headers: headers,
            validateStatus: function (status) {
                return status < 500; // Reject only if the status code is greater than or equal to 500
            }
        });

        //} else {
        // Object.assign(request, {
        //     data: {
        //         endpoint: request.endpoint,
        //         method: request.method || 'get',
        //         data: request.data
        //     }
        // });
        // return axios.post('/api', request, headers);
        //}       
    },

    // Helper for creating the AXIOS promise object 
    callUrl: function (request) {

        let headers = {};
        if (request.header) {
            headers = request.header;
        }

        // default headers
        //headers['Content-Type'] = 'FAV108';

        // update event
        //if (window.location.hostname == 'localhost') {
        return window.axios({
            method: request.method || 'get',
            data: request.data,
            url: request.endpoint,
            headers: headers,
            validateStatus: function (status) {
                return status < 500; // Reject only if the status code is greater than or equal to 500
            }
        });

        //} else {
        // Object.assign(request, {
        //     data: {
        //         endpoint: request.endpoint,
        //         method: request.method || 'get',
        //         data: request.data
        //     }
        // });
        // return axios.post('/api', request, headers);
        //}       
    },

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    shuffle: function(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    },

    copyToClipboard: function(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text);

        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    },

    isStale(itemDate, max){
        
        if(!itemDate){
            return true;
        }
        
        if(!max){
            max = 300000; // 5 minutes
        }

        //console.log(itemDate, max, (Date.now() - itemDate > max));

        return (Date.now() - itemDate > max);

    },
    
    trackEvent(options){
        let isDebug = location.href.indexOf('localhost') !== -1;
        let thisEvent = options.eventType || 'event';
        let otherOptions = options.options || {};
        window.gtag(thisEvent, options.action || 'click', {
            'event_category': options.category,
            'event_label': options.label,
            'value': options.value,
            'debug_mode': isDebug,
            ...otherOptions
          });
    },

    trackPageView(options){
        window.gtag('event', 'page_view', {
            page_title: options.title,
            page_location: options.path || location.pathname,
          })
    },
}