import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';
const vuexLocalStorage = new VuexPersist({
  key: 'treetapp', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

Vue.use(Vuex)

const set = property => (store, payload) => (store[property] = payload);

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    existingEmail: null,
    user: null,
    existing: null,
    updatingItem: null,
    ux: {},
    geo: {},
  },
  mutations: {
    setExistingEmail: set('existingEmail'),
    setUser: set('user'),
    setExisting: set('existing'),
    setUpdatingItem: set('updatingItem'),
    setUx: set('ux'),
    setGeo: set('geo')
  },
  actions: {
  },
  modules: {
  }
})
