<template>
	<v-app dark id="imokay-web" :class="(user && user.uuid) ? 'authd' : ''" v-cloak>
		<template v-if="verifyEmail">
			<EmailConfirm @verified="initData(true)" />
		</template>
		<template v-if="ready">
			<TopNav :key="'top'+key" :user="user"/>
			<v-main id="main-cont" :class="(isAuthView) ? 'bg-tri' : 'bg-tri'">
				<v-container v-if="isAuthView" class="auth secondary" fill-height fluid pa-0>
					<LeftNav v-if="$vuetify.breakpoint.smAndDown" />
					<router-view></router-view>
				</v-container>
				<v-container v-if="1==2 && user.uuid && !user.email_verified_at">
					<v-row style="height: 90vh;" dense align="center">
						<v-col cols="12">
							<EmailVerify />
						</v-col>
					</v-row>
				</v-container>
				<v-container fluid class="py-0 px-0 auth app-main TEMPdark-bg is-onboarding" v-if="!isAuthView && user && user.uuid && !user.onboarded">
					<LeftNav v-if="$vuetify.breakpoint.smAndDown" />
					<v-row no-gutters>
						<v-col cols="12" class="pb-0">
							<router-view></router-view>
						</v-col>
					</v-row>
					<Footer />
				</v-container>
				<v-container fluid class="py-0 px-0 auth app-main TEMPdark-bg " v-if="!isAuthView && user && user.uuid && user.onboarded">
					<LeftNav v-if="$vuetify.breakpoint.smAndDown" />
					<v-row no-gutters>
						<v-col cols="12" class="pb-0 pb-md-10">
							<router-view></router-view>
						</v-col>
					</v-row>
					<Footer />
				</v-container>
				<v-container fluid v-if="!isAuthView && !user.uuid" class="pt-0 px-0"> 
					<LeftNav v-if="$vuetify.breakpoint.smAndDown" />
					<v-row no-gutters>
						<v-col cols="12" class="">
							<router-view></router-view>
						</v-col>
					</v-row>
					<Footer />
				</v-container>
			</v-main>
		</template>
		<template v-else>
			<div id="app-loading">
				<v-icon color="primary" size="280">fa fa-check-double</v-icon>
			</div>
		</template>
			
		<SnackBar />

		<PhoneVerify v-if="verifyPhone" @closed="verifyPhone=false" />

	</v-app>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import LeftNav from '@/components/ui/LeftNav';
import TopNav from '@/components/ui/TopNav';
import Footer from '@/components/ui/Footer';

export default {
	name: 'ImOkayApp',

	components: {
		EmailConfirm: () => import('@/views/auth/EmailConfirm'),
		EmailVerify: () => import('@/views/auth/EmailVerify'),
		LeftNav,
		PhoneVerify: () => import('@/components/common/PhoneVerify'),
		SnackBar: () => import('@/components/ui/SnackBar'),
		TopNav,
		Footer
	},

	computed: {
		...mapState(['user', 'apiKey', 'geo', 'existing', 'ux']),	
		isAuthView(){
			if(this.isPublicRoute()){
				return false;
			}
			if(!this.isPublicRoute() && !this.user.uuid){
				return true;
			}
			if(['/', '/how-it-works'].indexOf(this.$route.path) !== -1 && !this.user.uuid){
				return false;
			}

			let authPaths = ['login', 'logout', 'signup', 'auth'];

			return authPaths.indexOf(this.$route.path) !== -1;
		}
	},
	data: () => ({
		ready: false,
		key: 0,
		refreshInterval: null,
		unAuth: false,
		verifyPhone: false,
		verifyEmail: false,
		//activityItems: [],
	}),
	methods: {
		...mapMutations(['setUser', 'setExisting', 'setUx', 'setGeo']),
		initData(){
			// this.verifyEmail = false;
			// if(!verified && location.href.indexOf('neverify') !== -1){
			// 	this.verifyEmail = true;
			// 	// return;
			// }
			this.initGeo();
			this.initUser();
			this.protectAuthRoutes();
		},
		protectAuthRoutes(){
			if(location.href.indexOf('/app') !== -1 && 	!this.user.uuid){
				this.$router.push('/login');
			}
		},
		isPublicRoute(){
			if(this.$route.path == '/'){
				return true;
			}
			let allowedNames = ['howitworks', 'faqs', 'about', 'alertDemo','alert', 'media', 'terms', 'privacy', 'support'];
			return allowedNames.indexOf(this.$route.name) !== -1;
		},
		initUser() {

			if(this.user && this.user.api_token){
				
				// check user using api key
				let request = {
					endpoint: '/auth/user?' + this.geoToUrl(), 
					method: 'get',
					data: {},
					apiKey: this.user.api_token || null
				};

				this.$appFn.callApi(request).then((res)=>{

					if(res.status !== 200){
						this.setUser({});
						if(!this.isPublicRoute()){
							this.handleInvalidClient();
						}else{
							this.ready = true;
						}
						return;
					}

					this.setUser(res.data);

				}).catch(()=>{
				}).finally(()=>{
					this.ready = true;
					this.refreshInterval = setInterval(()=>{ this.refreshUser() }, 60000);
					++this.key;
				});

				this.setExisting(true);

			}else{
				if(!this.isPublicRoute()){
					this.handleInvalidClient();
				}else{
					this.unAuth = true;
					this.setUser({});
					this.ready = true;
				}
			}
	
		},
		geoToUrl(){
			let lat = this.geo.lat || null;
			let lng = this.geo.lng || null;
			return 'lat=' + lat + '&lng=' + lng;
		},
		handleInvalidClient(){
			this.ready = true;
			this.$router.push('/');
		},
		onDeviceReady() {
			if ( navigator.geolocation ) {
				navigator.geolocation.getCurrentPosition( this.setUserLocation );
			}
        },
        initGeo(){
			if(location.href.indexOf('/app') !== -1){
				if ( navigator.geolocation && this.user ) {
					navigator.geolocation.getCurrentPosition( this.setUserLocation );
				}
				//document.addEventListener("deviceready", this.onDeviceReady, false);
			}
		},
		setUserLocation(position){
			this.setGeo({
				'lat': position.coords.latitude,
				'lng': position.coords.longitude
			})
		},
		updateUx(data){
			let ux = this.ux;
			Object.assign(ux, data);
			this.setUx(ux);
		},
		refreshUser(){
			if(this.user && this.user.uuid){
				// check user using api key
				let request = {
					endpoint: '/auth/user', 
					method: 'get',
					data: {},
					apiKey: this.user.api_token || null
				};
				this.$appFn.callApi(request).then((res)=>{
					this.setUser(res.data);
					if(!res.data.phone_verified && res.data.onboarded){
						this.verifyPhone = true;
					}
				});
			}
		},
		handleLogin(){
			this.ready = false;
		},
		handlePhoneVerify(){
			this.verifyPhone = true;
		}
	},
	created() {
		this.$eventHub.$on('user', ($event) => this.setUser($event));
		this.$eventHub.$on('refreshUser', () => this.refreshUser());
		this.$eventHub.$on('ux', ($event) => this.updateUx($event));
		this.$eventHub.$on('loggingin', () => this.handleLogin());
		this.$eventHub.$on('verifyPhone', () => this.handlePhoneVerify());
	},
	mounted() {
		this.initData();
	},
	watch: {
		$route(){
			document.body.scroll(0, 0);
		},
	}
};
</script>
<style lang="scss">

	@import "./styles/app.scss";
</style>